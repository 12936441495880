@tailwind base;
@tailwind components;
@tailwind utilities;

body,
pre {
  margin: 0;
  padding: 0;
  font-family: theme('fontFamily.default') !important;
  font-size: 100%;
  color: theme('colors.body1');
  overflow: hidden;
  height: 100vh;
}

button,
input {
  font-family: theme('fontFamily.default') !important;
}

.ojo-loading {
  width: 72px;
  height: 72px;
  position: relative;
  border-radius: 100%;
}

.ojo-loading img {
  height: 4rem;
  width: 4rem;
}

.ojo-loading:after {
  content: '';
  position: absolute;
  top: calc(-1 * 1px);
  left: calc(-1 * 1px);
  height: calc(100% + 1px * 2);
  width: calc(100% + 1px * 2);
  background: linear-gradient(
    60deg,
    #00a5d6,
    #00b0ff,
    #00b0ff,
    #7fe9fb,
    #7fe9fb,
    #e6fbfe
  );
  border-radius: 100%;
  z-index: -1;
  animation: animatedgradient 2s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.input--without-horizontal-padding {
  width: 100%;
  display: block;
  border-radius: theme('borderRadius.sm');
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 56px !important;
  border-width: 2px;
  border-style: solid;
}

@media (min-width: 310px) {
  .verticalDivider {
    width: 0.05rem;
    height: 200px;
    margin-left: 0.9rem;
    background: theme('colors.background2');
    display: inline-block;
    vertical-align: middle;
  }

  .phoneInputWrapper {
    position: relative;
  }

  .phoneInputLabel {
    position: absolute;
    left: 0;
    margin-left: 1rem;
    top: 0.9rem;
    text-align: center;
  }
}

@media (max-width: 310px) {
  .phoneInputLabel {
    display: none;
  }
}

/* Font Weight */
.font-normal {
  font-family: theme('fontFamily.default');
}

textarea:focus,
input:focus {
  outline: none;
}
